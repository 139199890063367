import gsap from 'gsap'
import {qs,qsa} from '../utils/lib.js'
export default class PrVariant{
	constructor(el){
		this.popup = el
		this.close_btn = this.popup.querySelector('.variant_popup_header_close')
		this.prev = this.popup.querySelector('.prev')
		this.next = this.popup.querySelector('.next')
		this.els = qsa('.variant_popup_el', this.popup)
		this.image = qs('.variant_popup_el')
		this.row = 3
		this.active = 0
		this.elsLength = this.els.length
		this.isDevice = window.innerWidth < 768
		this.list = this.popup.querySelector('.variant_popup_list_els')
		this.duration = .8
		this.isAnimating = false
	}
	init(){
		qsa('.js_open_popup').forEach((btn) => {
			btn.addEventListener('click',() => {
				this.open()
			})
		})
		this.close_btn.addEventListener('click',this.close.bind(this))
		if (this.isDevice) {
			this.row = 1
		}	
		if ( this.elsLength > 0 ) {
			this.prev.addEventListener('click',this.goTo.bind(this,-1))
			this.next.addEventListener('click',this.goTo.bind(this,1))
				

			this.bindEls()		
		}
		

	
	}
	bindEls(){
		gsap.set(this.els,{
			opacity: 0
		})
		this.fadeOutArrow(this.prev)
		if(this.elsLength <= this.row){
			this.fadeOutArrow(this.next)		
		}
	}
	open(){
        this.popup.classList.add('open')
        this.animateEls()
	}
	close(){
        this.popup.classList.remove('open')
        this.bindEls()
	}
	animateEls(){
		gsap.set(this.els,{
			opacity: 1,
			duration: this.duration,
			stagger: .1
		})
	}
	goTo(dir){
		if(this.animating) {
			return 
		}
		this.animating = true
		this.active += dir
		const width = this.image.offsetWidth
		gsap.to(this.list,{
			x: `-=${width * dir}`,
			duration: .6,
			onComplete: () => {
				this.checkActive()
				this.animating = false
			}
		})
	}

	checkActive(){
		if(this.active <= 0) {
			this.fadeOutArrow(this.prev)
			this.fadeInArrow(this.next)			
		} else if(this.active == this.elsLength - this.row) {
			this.fadeOutArrow(this.next)	
			this.fadeInArrow(this.prev)								
		} else {
			this.fadeInArrow(this.prev)	
			this.fadeInArrow(this.next)						
		}
	}
    fadeOutArrow(el) {
        el.classList.add('disable')
        gsap.to(el, {
            opacity: 0,
            duration: .2
        })
    }
    fadeInArrow(el) {
        gsap.to(el, {
            opacity: 1,
            duration: .2,
            onComplete: () => {
                el.classList.remove('disable')
            }
        })
    }	
}
