import React, { Component } from 'react'


export default class ConfiguratorView extends Component {

	state = {
		images: this.props.images
	}

	componentDidMount() {
		
	}


	render() {
		return (
			<div className="configurator_view_content"> 
				{this.state.images.map( (img,i ) => (
					<img key={i} src={img.options[img.active].image} />
				))}
			</div>
		)
	}

}