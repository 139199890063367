import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'

export default class Accordion {
    constructor(el) {
        this.el = el
        this.btns = qsa('.accordion_row_title', this.el)
        this.texts = qsa('.accordion_row_text_p', this.el)
        this.current = ""
    }
    init() {
        this.setElement(0)        
        this.btns.forEach((btn, index) => {
            this.resetPosition(index)
            btn.addEventListener('click', () => {
                this.setElement(index)
            })
        })
    }
    resetPosition(index) {
        const row = this.btns[index].parentNode
        const text = qs('.accordion_row_text_p',row)
        gsap.to(text, {
            height: 0,
            duration: 0
        })
    }
    setElement(index) {
        if (index === this.current) {
            this.animateOut(index)
            this.current = ""
        } else {
            this.current = index
            this.animate()
        }
    }
    animate() {
        this.btns.forEach((btn, index) => {
            if (index == this.current) {
                this.animateIn(index)
            } else {
                this.animateOut(index)
            }
        })
    }
    animateIn(index) {
        let row = this.btns[index].parentNode
        let text = qs('.accordion_row_text_p',row)
        let arrow = qs('.arrow',row)

        gsap.to(text, {
            height: "auto",
            duration: .5,
            opacity: 1
        })

        gsap.to(arrow, {
            rotate: "90deg"
        })
    }

    animateOut(index) {
        let row = this.btns[index].parentNode
        let text = qs('.accordion_row_text_p',row)
        let arrow = qs('.arrow',row)

        gsap.to(text, {
            height: 0,
            duration: .5,
            opacity: 1
        })

        gsap.to(arrow, {
            rotate: "-90deg"
        })

    }

}