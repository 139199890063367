import React, { Component } from 'react'
import Data from '../data/sidebar.js'


export default class ConfiguratorView extends Component {


    state = {
        options: this.props.models
    }

    componentDidMount() {
    	
    }

    resetActive(i) {
        let arrayImages = this.state.options
        arrayImages[i].active = 0             
        this.setState({
            options: arrayImages
        })
        this.props.resetOption(i)
    }

    render() {
        return (
            this.state.options.map((el, i) => (

                <p key={i} className={el.active != 0 ? "visible" : ""}>{el.active != 0 ? el.text : ""}: <span className="bold capitalize">{el.active != 0 ? el.options[el.active].text : ""}</span><span className="bold mono delete" onClick={this.resetActive.bind(this,i)}>x</span></p>
            ))
        )
    }

}