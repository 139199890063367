import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'

export default class PrPager{
	constructor(el){
		this.el = el
		this.prev = qs('.pr_pager_prev',this.el)
		this.next = qs('.pr_pager_next',this.el)
	}
	init(){
		[this.prev,this.next].forEach((item) => {
			item.addEventListener('mouseenter',this.animateIn.bind(this,item))
			item.addEventListener('mouseleave',this.animateOut.bind(this,item))			
		})
	}

	animateIn(el){
		let text = el.querySelector('.pr_pager_title')
		if (el == this.next) {
			gsap.to(text,{
				x: 16,
				opacity: 1,
				duration: .8
			})			
		} else {
			gsap.to(text,{
				x: -16,
				opacity: 1,
				duration: .8
			})				
		}

	}
	animateOut(el){
		let text = el.querySelector('.pr_pager_title')
		gsap.to(text,{
			x: 0,
			opacity: .6,
			duration: .8
		})
	}
}