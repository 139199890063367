import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'
import FadeBlock from './fade_block.js'
export default class PrGrid{
	constructor(el){
		this.el = el
		this.items = qsa('.products_grid_item',this.el)
		this.duration = .4
		this.img = ""
		this.text = ""
		this.active_filter = false
		this.buttons = qsa('.js-filters')
	}
	init(){
		this.buttons.forEach((btn) => {
			btn.addEventListener("click",() => {
				if ( this.active_filter == btn.dataset.cat ) {
					this.active_filter = false
				} else {
					this.active_filter = btn.dataset.cat
				}
				this.applyFilters()
			})
		})
		this.items.forEach((item) => {
			item.addEventListener('mouseover', () => {
				this.img = qs('img',item)
				this.text = qs('.products_grid_item_text',item)				
				this.fadeOut()
			})
			item.addEventListener('mouseout', () => {
				this.img = qs('img',item)
				this.text = qs('.products_grid_item_text',item)					
				this.fadeIn()
			})
		})
	}

	applyFilters() {
		if ( this.active_filter ) {
			this.hideItems()
		} else {
			this.resetFilters()
		}
		
	}

	hideItems() {
		this.items.forEach((it) => {
			if ( it.dataset.cat != this.active_filter ) {
				it.classList.add('hidden')
			} else {
				it.classList.remove('hidden')
			}
		})
		this.buttons.forEach((it) => {
			if ( it.dataset.cat != this.active_filter ) {
				it.classList.add('hidden')
			} else {
				it.classList.remove('hidden')
			}
		})
		FadeBlock()
	}

	resetFilters() {
		this.items.forEach((it) => {
			if ( it.dataset.cat != this.active_filter ) {
				it.classList.remove('hidden')
			}
		})
		this.buttons.forEach((it) => {
			it.classList.remove('hidden')
		})
	}
	fadeOut(){
		gsap.to(this.img,{
			opacity: 0,
			duration: this.duration
		})
		gsap.to(this.text,{
			opacity: 1,
			duration: this.duration
		})
	}
	fadeIn(){
		gsap.to(this.img,{
			opacity: 1,
			duration: this.duration
		})
		gsap.to(this.text,{
			opacity: 0,
			duration: this.duration
		})
	}
}