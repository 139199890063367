import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'

export default class PrMenuMobile{
	constructor(el){
		this.el = el
		this.menu = qs('.pr_navbar_menu', this.el)
		this.arrow = qs('.pr_navbar_title_arrow', this.el)
		this.title = qs('.pr_navbar_title', this.el)
		this.title_height = this.title.offsetHeight
		this.menu_height = this.el.offsetHeight
		this.total_height = this.title_height + this.menu_height
		this.open = false
	}
	init(){
		this.arrow.addEventListener('click',() => {
			if(this.open == false) {
				this.openMenu()
			} else {
				this.closeMenu()
			}
		})
	}

	openMenu(){
		this.open = true
		this.menu.classList.add('open')
		gsap.to(this.menu,{
			opacity: 1,
			y: -this.menu_height,
			duration: .8
		})
		gsap.to(this.arrow,{
			rotate: '-180deg',
			duration: .8
		})
	}
	closeMenu(){
		gsap.to(this.arrow,{
			rotate: 0,
			duration: .8
		})			
		gsap.to(this.menu,{
			opacity: 0,
			y: 0,			
			duration: .8,
			onComplete: () => {		
				this.open = false
				this.menu.classList.remove('open')
			}
		})
	}
}