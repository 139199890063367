import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'

export default function() {
    const elements = qsa('[fadeBlock]')
    elements.forEach(el => {
        elements.forEach((el) => {
            gsap.set(el, { y: 24, opacity: 0 })
        })
        el.observer = new IntersectionObserver(
            entry => {
                if (entry[0].intersectionRatio > 0) {
                    gsap.ticker.add(el.progressTween);
                } else {
                    gsap.ticker.remove(el.progressTween);
                }
            }
        );
        el.progressTween = () => {
            const top = el.getBoundingClientRect().top
            gsap.to(el, {
                y: 0,
                opacity: 1,
                duration: 0.8,
                delay: 0.4
            })
        }
        el.observer.observe(el);
    });
}