import { ScrollScene } from 'scrollscene'
import { gsap } from 'gsap'
import { qs, qsa } from '../utils/lib'

export default class ScrollAnimations {
	constructor(){
		this.scrollScene = null
		this.els = qsa('.js-scrollmagic')
	}
	init(){
		this.els.forEach((el) => {
			this.animate(el)
		})
	}

	animate(el){
		const mode = el.dataset.mode
		
		if ( mode == 'scroll_change') {
			this.scrollChange(el)
		}
		if ( mode == 'horizontal_scroller') {
			this.horizontalScroller(el)
		}
		if ( mode == 'scroll_change_col') {
			this.scrollChangeCol(el)
		}
	}

	horizontalScroller(el) {
		const cont = qs('.horizontal_scroller_cont',el)
		const tween = gsap.timeline({ paused: true })
		const scroller_el = qsa('.horizontal_scroller_slide',el)
		const amount = scroller_el[0].getBoundingClientRect().width * (scroller_el.length - 1)
		tween.to(cont, {
       		x: -amount,
       		duration: 3
		})
		this.addToScene(el.parentNode,tween)
	}

	scrollChange(el) {
		const images  = qsa('img',el)
		const tween = gsap.timeline({ paused: true })
		images.forEach((img,i) => {
				tween.to(images[i], {
			        opacity: 1,
			        duration: .5
				})
		})
		this.addToScene(el,tween)
	}

	scrollChangeCol(el) {
		const images  = qsa('img',el)
		const tween = gsap.timeline({ paused: true })
		images.forEach((img,i) => {
				tween.to(images[i], {
			        opacity: 1,
			        duration: .5
				})
		})
		this.addToScene(el,tween)
	}

	destroy() {
		if ( this.scrollScene ) {
			this.scrollScene.Scene.destroy(true)
			this.scrollScene.destroy()
		}
	}

	addToScene(trigger, tween, duration = 3000,) {
		this.scrollScene = new ScrollScene({
		  triggerElement: trigger,
		  triggerHook: 0,
		  gsap: {
		    timeline: tween
		  },
		  duration: duration
		})
		this.scrollScene.Scene.setPin(trigger)
	}
	
}