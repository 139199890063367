import '../../scss/application.scss'
import {qs,qsa} from './utils/lib.js'
import ScrollAnimations from './components/scroll_animations.js'
import Highway from '@dogstudio/highway/src/highway';
import Transition from './components/transition.js';
import gsap from 'gsap';
import MenuOpen from './components/menu.js'
import MenuAnimations from './components/menu_color.js'
import Accordion from './components/accordion.js'
import PrGrid from './components/pr_grid.js'
import SliderBrand from './components/slider_brand.js'
import Slider from './components/slider.js'
import ContactForm from './components/contact_form.js'
import Parallax from './components/parallax.js'
import FadeInOutText from './components/fade_in_out_text.js'
import FadeBlock from './components/fade_block.js'
import PrMenuMobile from './components/pr_menu_mobile.js'
import PrPager from './components/pr_pager.js'
import PrNavbar from './components/pr_navbar.js'
import defineComponents from './utils/define.jsx'
import Configurator from './components/configurator.jsx'
import AddToCart from './components/add_to_cart'
import PrVariant from './components/slider_pr_variant.js'
import HpPopup from './components/hp_popup'

let scroll_animation;


document.addEventListener('DOMContentLoaded', () => {
    new defineComponents({
        '.configurator' : Configurator
    }, () => {

    }).load()
	App()
    Animations()
	const H = new Highway.Core({
	  transitions: {
	    default: Transition
	  }
	});


    function Animations() {
        FadeInOutText() 
        Parallax()      
        FadeBlock()            
        FadeInOutText()
        PrNavbar()   
    }

    function App() {

        AddToCart()
        const hp_p = qs('.js-hp-popup')
        hp_p && new HpPopup(hp_p)
        const trigger = qs('#billing_trigger')
        if ( trigger ) {
            trigger.addEventListener("change",(e) => {
                qs('.checkout_form_col.billing').classList.toggle("open")
            })

        }
        const product_popup = qs('.variant_popup')
        if ( product_popup ) {
            const pr_pop = new PrVariant(product_popup)
            pr_pop.init()
        }
            
        const pagers = qsa('.js-pr-pager')
        pagers.forEach((pr) => {
            const pr_pager = new PrPager(pr)
            pr_pager.init()
        })

        const pr_menu_mob_btns = qsa('.js-pr-menu-mobile')
        pr_menu_mob_btns.forEach((btn) => {
            const pmm = new PrMenuMobile(btn)
            pmm.init()
        }) 


        const ct_form = qs('.js-contact-form')
        if (ct_form) {
            const form = new ContactForm(ct_form)
            form.init()
        }


    	const slider = qs('.js-slider')
    	if (slider) {
    		const sl = new Slider(slider)
    		sl.init()
    	}
    	const slider_brand = qs('.js-slider-brand')
    	if (slider_brand) {
	     	const sl_br = new SliderBrand(slider_brand)
	    	sl_br.init()   		
    	}



    	const prs_grid = qsa('.js-pr-grid')
    	prs_grid.forEach((grid) => {
    		const pr_gr = new PrGrid(grid)
			pr_gr.init()
    	})


    	const menu_btn = qs('.js-menu')
    	if ( menu_btn ) {
            MenuOpen(menu_btn)
    	}
        const menu = qs('.menu')
        const m = new MenuAnimations(menu)
        m.init()

        const ani = qs('.js-scrollmagic')

        scroll_animation = new ScrollAnimations()
        scroll_animation.init()

		const accordions = qsa('.js-accordion')
		accordions.forEach((acc) => {
			const accord = new Accordion(acc)
			accord.init()
		})
    }

    H.on('NAVIGATE_IN', ({ from, trigger, location }) => {

    });


    H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

        if ( scroll_animation ) {
            H.cache.clear()
            scroll_animation.destroy()
        }
        
	});


    H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
        App()
        if (typeof gtag !== 'undefined') {
		    gtag('config', 'UA-168617612-1', {
		      'page_path': location.pathname,
		      'page_title': to.page.title,
		      'page_location': location.href
		    });
		  }
	});

})
