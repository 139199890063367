import gsap from 'gsap'

export default function(){
	const navbar = document.querySelector('.pr_navbar')	
	function trigger(){
		const navbar = document.querySelector('.pr_navbar')			
		const navbar_position = navbar.getBoundingClientRect().top		
		const header = document.querySelector('.header_col')
    	const paddTop = window.getComputedStyle(header, null).getPropertyValue("padding-top")
    	const trigger = navbar_position - parseInt(paddTop)
    	return trigger
	}	
	if (navbar) {
		window.addEventListener('scroll', () => {
			
		})
	}
}