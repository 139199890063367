import React, { Component } from 'react'
import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'

export default class Configurator extends Component {
	state = {
		colors: ['black','red','dodgerblue'],
		duration: .5
	}
	componentDidMount(){
	}
	showColor(e){
		let target = e.target
		let text_color = target.querySelector('.configurator_filtercolors_index_item_btn > span')
		gsap.to(text_color,{
			opacity: 1,
			duration: this.state.duration
		})
	}
	hideColor(e){
		let target = e.target
		let text_color = target.querySelector('.configurator_filtercolors_index_item_btn > span')	
		gsap.to(text_color,{
			opacity: 0,
			duration: this.state.duration
		})
	}	

	chooseColor(color) {
		this.props.close(color)
	}
	closeFilters(){
		if (this.props.open) {	
			this.props.close(0)
		}
			
	}
	render(){
		return (
			<div className={"configurator_filtercolors " + (this.props.open ? "visible" : "" )}>
				<div className="configurator_filtercolors_header only-mobile">
					<h1 className="light h3">Display</h1>
					<img src="/images/close.svg" onClick={this.closeFilters.bind(this)} />
				</div>				
				<div className="configurator_filtercolors_index">
					{this.props.options.options.map((color,i) => (
						<div className="configurator_filtercolors_index_item" key={i}>
							<button 
							onClick={this.chooseColor.bind(this,i)} 
							onMouseOver={this.showColor.bind(this)} 
							onMouseLeave={this.hideColor.bind(this)}  
							className="configurator_filtercolors_index_item_btn" 
							style={{background: color.text}}>
								<span className="configurator_filtercolors_index_item_text">{color.text}</span>
							</button>
						</div>
					))}
				</div>

			</div>
			)
	}
}