import React, { Component } from 'react'
import { qs, qsa } from '../utils/lib'
import gsap from 'gsap'
import Data from '../data/sidebar.js'

export default class Configurator extends Component {
    state = {
        duration: .5,
        data: Data
    }
    componentDidMount() {

    }
    openList() {
        const list = qs('.configurator_index_list')
        const header = qs('.configurator_header')
        gsap.to(header, {
            left: '15%',
            duration: this.state.duration
        })
        gsap.to(list, {
            left: '0',
            duration: this.state.duration
        })

    }
    closeList() {
        const list = qs('.configurator_index_list')
        const header = qs('.configurator_header')
        gsap.to(header, {
            left: '0',
            duration: this.state.duration
        })
        gsap.to(list, {
            left: '-100%',
            duration: this.state.duration
        })

    }

    render() {
        return (
            <div className="configurator_index">
				<button onClick={this.openList.bind(this)} className="configurator_index_title">Scegli il modello</button>
				<div className="configurator_index_list">
					<div className="configurator_index_list_header">
						<img src="/images/close.svg" onClick={this.closeList.bind(this)}/>
						<p className="regular h5">Varianti disponibili</p>
					</div>
					<div className="configurator_index_list_container">
						<div className="configurator_index_list_container_items">
							{this.state.data.map((item,i) => (
							<div key={i} className="configurator_index_list_container_items_item">
								<div className="configurator_index_list_container_items_item_image">
									<img src={item.image} />
								</div>
								<p>{item.text}</p>						
							</div>
							))}	
						</div>
					</div>	
				</div>
			</div>
        )
    }
}