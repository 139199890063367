import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'

export default class MenuAnimations {
    constructor(el) {
        this.el = el
        this.items = qsa('.js-item',this.el)
        this.index = qs('.products_index',this.el)
        this.color = "#D6D1CD"
        this.duration = .4
        this.default_color = "#D6D1CD"
        this.image = qs('.menu_background',this.el)
        this.source = this.image.src
        this.transitioner = qs('.transitioner')
    }
    init() {
        this.items.forEach((item) => {
            item.addEventListener('mouseover', () => {
                this.changeColor(item)
                this.changeImage(item)
            })
            item.addEventListener('mouseleave', () => {
                this.resetColor()
                this.resetImage()
            })
        })
        this.index.addEventListener('mouseleave', () => {
            this.resetColor()
            this.resetImage()
        })
    }
    changeColor(link) {
        this.color = link.getAttribute('data-color')
        gsap.to(this.el, {
            backgroundColor: this.color,
            duration: this.duration
        })
        this.transitioner.style.background = this.color
    }
    resetColor() {
        gsap.to(this.el, {
            backgroundColor: this.default_color,
            duration: this.duration
        })
    }
    changeImage(img) {
        gsap.set(this.image, {
            opacity: 1
        })
        this.source = img.getAttribute('menu-image')
        this.image.src = this.source
    }
    resetImage() {
        gsap.set(this.image, {
            opacity: 0
        })
    }
}