import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'

export default function(ham) {
    const menuw = qs('.menu')
	const duration = .7
	const slices = qsa('span',ham)
	const links = qsa('.item',menuw)
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
    ham.addEventListener('click', () => {
    	if(!menuw.classList.contains('open')){
    		slices.forEach((sl) => {
    			sl.classList.add('open')
    		})
		    gsap.to(menuw, {
		        x: 0,
		        duration: duration,
		        onComplete: () => {
		        	staggerAnim()
		        	menuw.classList.add('open')
		        }
		    })
    	} else {
      		slices.forEach((sl) => {
    			sl.classList.remove('open')
    		}) 		  		
			gsap.to(menuw, {
				x: '-100%',
				duration: duration,
				onComplete: () => {
					menuw.classList.remove('open')
				}
			})
    	}
    })
    function staggerAnim(){
    	const tl = gsap.timeline()
    	links.forEach((el) => {
	    	tl.to(el,{
	    		opacity: 1,
	    		duration: .07,
	    		onComplete: () => {
	    			el.classList.add('active')
	    		}
	    	})    		
    	})
    }
}