import React, { Component } from 'react'
import View from "./configurator_view.jsx"
import Sidebar from "./configurator_sidebar.jsx"
import Index from './configurator_index.jsx'
import Data from '../data/sidebar.js'
import Model from './configurator_model.jsx'

export default class Configurator extends Component {

	state = {
		options: Data,
		product: JSON.parse(this.props.prod),
		price: 800.00

	}

	componentDidMount() {
	}

	

	getColor(){
		const color = this.state.product.color
		return color		
	}
	getPrice(){
		const options = this.state.options
		let options_price = 0
		options.forEach((opt) => {
			if ( opt.active > 0 ) {
				options_price += opt.options[opt.active].price
			}
		})
		const price = this.state.price + options_price
		return `${price.toFixed(2)}`
	}

	setOption(option,active) {

		let options = this.state.options
	    
		console.log(options)
	    options[option].active = active
	    this.setState({
	    	options: options
	    })
	    this.getPrice()
	}

	resetOption(option) {
		let options = this.state.options
	    options[option].active = 0
	    this.setState({
	    	options: options
	    })
	    this.getPrice()
	}

	checkout() {
		let obj = {
    			id: this.props.product,
    			price: parseFloat(this.getPrice())
    	}
    	console.log(obj)
    	this.postData(this.props.url,obj)
    		.then(data => {
    			window.location = data.redirect
    		})
	}

	async postData(url = '', data = {}) {
	  // Default options are marked with *
	  const response = await fetch(url, {
	    method: 'POST', // *GET, POST, PUT, DELETE, etc.
	    mode: 'cors', // no-cors, *cors, same-origin
	    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	    credentials: 'same-origin', // include, *same-origin, omit
	    headers: {
	      'Content-Type': 'application/json',
	      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
	      // 'Content-Type': 'application/x-www-form-urlencoded',
	    },
	    redirect: 'follow', // manual, *follow, error
	    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	    body: JSON.stringify(data) // body data type must match "Content-Type" header
	  });
	  return response.json(); // parses JSON response into native JavaScript objects
	}

	render() {
		return (
			<div className="configurator_cont">
				<div className="configurator_header">
					<img src="/images/logo_black.svg" alt="" />
					<a className="configurator_header_back c-black">
						<img src="/images/Download_left.svg" />
						<p className="mono h5">Torna al prodotto</p>
					</a> 					
				</div>
				<Index />
				<div className="configurator_view">
					<View images={this.state.options} />
				</div>
				<div className="configurator_sidebar">
					<Sidebar setOption={this.setOption.bind(this)} />
				</div>
				<div className="configurator_product">
					<p style={{color: this.getColor()}}>{this.state.product.title_it}</p>
				</div>
				<div className="configurator_model">
					<Model resetOption={this.resetOption.bind(this)} models={this.state.options} />
				</div>
				<div className="configurator_delivery">
					<div className="configurator_delivery_text">
						<p className="bold">Consegna prevista:</p>
						<p className="mono" style={{color: this.getColor()}}>05.06.2020</p>
					</div>
				</div>
				<div className="configurator_price">
					<p>{this.getPrice()} €</p>
				</div>
				<div className="configurator_btn" style={{background: this.getColor()}}>
					<button onClick={this.checkout.bind(this)}>Acquista ora</button>
				</div>
			</div>
		)
	}

}