export default [
	{
		"image": "https://fabita-web.s3.amazonaws.com/uploads/configurator_part/image/1/A_0_base.png",
		"text": "Cavi",
		"active": 0,
		"options": [{"text":"Base","price":45,"image":"https://fabita-web.s3.amazonaws.com/uploads/configurator_variant/image/1/A_0_base.png"}]
	}, 
	{
		"image": "https://fabita-web.s3.amazonaws.com/uploads/configurator_part/image/2/A_1_thumb.png",
		"text": "Base Cottura",
		"active": 0,
		"options": [{"text":"nero","price":37,"image":"https://fabita-web.s3.amazonaws.com/uploads/configurator_variant/image/2/A_1_color1.png"},{"text":"bianco","price":21,"image":"https://fabita-web.s3.amazonaws.com/uploads/configurator_variant/image/3/A_1_color2.png"},{"text":"rame","price":10,"image":"https://fabita-web.s3.amazonaws.com/uploads/configurator_variant/image/4/A_1_color3.png"}]
	}, 
	{
		"image": "https://fabita-web.s3.amazonaws.com/uploads/configurator_part/image/3/A_2_thumb.png",
		"text": "Cottura",
		"active": 0,
		"options": [{"text":"nero","price":15,"image":"https://fabita-web.s3.amazonaws.com/uploads/configurator_variant/image/5/A_2_color1.png"},{"text":"bianco","price":37,"image":"https://fabita-web.s3.amazonaws.com/uploads/configurator_variant/image/6/A_2_color2.png"}]
	}, 
	{
		"image": "https://fabita-web.s3.amazonaws.com/uploads/configurator_part/image/4/A_3_thumb.png",
		"text": "Controlli",
		"active": 0,
		"options": [{"text":"nero","price":11,"image":"https://fabita-web.s3.amazonaws.com/uploads/configurator_variant/image/7/A_4_color1.png"},{"text":"rosso","price":30,"image":"https://fabita-web.s3.amazonaws.com/uploads/configurator_variant/image/8/A_4_color4.png"}]
	} 
]