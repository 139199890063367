import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'

export default class ContactForm{
	constructor(el){
		this.el = el
		this.endpoint = "https://usebasin.com/f/426d72dbb2e5.json"
		this.form = el.querySelector('form')
		this.inputs =  qsa('.js-input',this.form)
	}
	init(){	
		this.inputs.forEach((input) => {
		    input.addEventListener('focus', () => {
		    	this.animate(input,1)
		    })
		    input.addEventListener('blur', () => {
		    	if (input.value == "") {
		    		this.animate(input,-1)		    		
		    	} 
		    })
		})
		this.form.addEventListener("submit", (e) => {
			e.preventDefault()
			this.submit()
			

		})
	}

	submit() {
		let data = {}
		this.inputs.forEach((i) => {
			data[i.name] = i.value
		})
		fetch(this.endpoint, {
		  method: 'post',
		  headers: {
		    'Accept': 'application/json, text/plain, */*',
		    'Content-Type': 'application/json'
		  },
		  body: JSON.stringify(data)
		}).then(res=>res.json())
		  .then(res => {
		  	this.reset()
		  });
	}

	reset() {
		this.form.reset()
		this.inputs.forEach((input) => {
		   this.animate(input,-1)
		})
	}
	animate(el,act){
		const label = el.nextElementSibling
		if (act > 0){
			label.classList.add('active')
		} else {
			label.classList.remove('active')
		}
	}
}