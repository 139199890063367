import { qs,qsa } from '../utils/lib'
import Highway from '@dogstudio/highway/src/highway';
import gsap from 'gsap';
import MenuAnimations from './menu_color.js'
import Parallax from './parallax.js'
import FadeInOutText from './fade_in_out_text.js'
import FadeBlock from './fade_block.js'

// Fade
class Fade extends Highway.Transition {
  in({ from, to, done }) {

    const trans = qs('.transitioner')
    const menu = qs('.menu')
    const items = menu.querySelectorAll('.item')    
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();
    function Animations() {
        FadeInOutText() 
        Parallax()      
        FadeBlock()    
        FadeInOutText()                           
        
    }

    Animations()

    items.forEach((item) => {
      gsap.set(item,{
        opacity: 0
      })
    })


    // Animation
    const tl = gsap.timeline()
    tl.to(trans,{
      y: "-100%",
      duration: .5,
      onComplete: () => {
        done()
      }
    }, 'some-label')
    
  }

  out({ from, done, trigger }) {
    const trans = qs('.transitioner')
    let trans_color = "#d6d1cd"
    if ( trigger == 'popstate') {
     trans_color = "#d6d1cd"
    } else {
      trans_color = trigger.getAttribute('data-color')
    }
    const menuw = document.querySelector('.menu')
    const ham = document.querySelector('.js-menu')
    const slices = ham.querySelectorAll('span')
    const links = menuw.querySelectorAll('.item')  
    const cont  = document.querySelector('.main_content')     
    const tl = gsap.timeline() 

    //set color transitioner
    tl.set(trans,{
      backgroundColor: trans_color
    })
    // Animation

    .to(menuw,{
      x: "-100%",
      duration: .4,
      onComplete: () => {
        menuw.classList.remove('open')
        slices.forEach((sl) => {
          sl.classList.remove('open')
        })
        links.forEach((el) => {
          el.classList.remove('active')
        })
      }
    })
    .to(trans,{
      y: "100%",
      duration: 0
    })
    .to(trans,{
    	y:0,
    	duration:.8,
      	onComplete: done
    })
  }
}

export default Fade;