import gsap from 'gsap'
import verge from 'verge'
import { qs, qsa } from '../utils/lib'

export default function() {
    const ctas = qsa('.js_add_to_cart')
    ctas.forEach((el) => {
    	el.addEventListener("click",(e) => {
    		e.preventDefault();
    		let obj = {
    			id: el.dataset.product
    		}
    		postData(el.dataset.url,obj)
    			.then(data => {
    				window.location = data.redirect
    		})
    	})
    })
    async function postData(url = '', data = {}) {
	  // Default options are marked with *
	  const response = await fetch(url, {
	    method: 'POST', // *GET, POST, PUT, DELETE, etc.
	    mode: 'cors', // no-cors, *cors, same-origin
	    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
	    credentials: 'same-origin', // include, *same-origin, omit
	    headers: {
	      'Content-Type': 'application/json',
	      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
	      // 'Content-Type': 'application/x-www-form-urlencoded',
	    },
	    redirect: 'follow', // manual, *follow, error
	    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	    body: JSON.stringify(data) // body data type must match "Content-Type" header
	  });
	  return response.json(); // parses JSON response into native JavaScript objects
	}
}
