import gsap from 'gsap'
import { qs, qsa } from '../utils/lib'
export default class SliderBrand {
    constructor(el) {
        this.el = el
        this.prev = qs('.arrow.left', this.el)
        this.next = qs('.arrow.right', this.el)
        this.row = qs('.slider_brand_track', this.el)
        this.icons = qsa('.slider_brand_track_icon', this.el)
        this.icon = this.icons[0]
        this.width = this.icon.offsetWidth
        this.active = 0
        this.length = this.icons.length
        this.duration = .4
        this.fadeOutArrow(this.prev)
        this.animating = false
        this.imgs_row = 4
        this.is_device = window.innerWidth < 768
    }
    init() {
        if (this.is_device) {
            this.imgs_row = 2
        }
        this.prev.addEventListener('click', this.goTo.bind(this, -1))
        this.next.addEventListener('click', this.goTo.bind(this, 1))
    }
    goTo(dir) {
        if (this.animating) {
            return
        } else {
            this.active = this.active + dir
            this.animate(dir)
        }
    }
    animate(direction) {
        this.animating = true
        if (this.active <= 0) {
            this.fadeOutArrow(this.prev)
        } else if (this.active == this.length - this.imgs_row) {
            this.fadeOutArrow(this.next)
        } else {
            this.fadeInArrow(this.prev)
            this.fadeInArrow(this.next)
        }
        gsap.to(this.row, {
            x: `-=${this.width * direction}`,
            duration: this.duration,
            onComplete: () => {
                this.animating = false
            }
        })
    }
    fadeOutArrow(el) {
        el.classList.add('disable')
        gsap.to(el, {
            opacity: 0,
            duration: this.duration
        })
    }
    fadeInArrow(el) {
        gsap.to(el, {
            opacity: 1,
            duration: this.duration,
            onComplete: () => {
                el.classList.remove('disable')
            }
        })
    }
}