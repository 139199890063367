import React, { Component } from 'react'
import Data from '../data/sidebar.js'
import FilterColors from './configurator_filtercolors.jsx'

export default class ConfiguratorSidebar extends Component {

	state = {
		data: Data,
		filters_open: false,
		selected: 0
	}

	componentDidMount() {
		
	}

	async selectOption(i,e) {
		this.setState({
			selected: i,
			filters_open: true
		})
	}

	applyColor(color,e) {
		this.props.setOption(this.state.selected,color)
		this.setState({
			filters_open: false
		})
	}


	render() {
		return (
			<div>
				<FilterColors 
					open={this.state.filters_open} 
					options={this.state.data[this.state.selected]} 
					close={this.applyColor.bind(this)} />
				<div className="configurator_sidebar_title">
					<div className="configurator_sidebar_title_text">
						<p>Personalizza il prodotto</p>
					</div>
				</div>
				<div className="configurator_sidebar_container">
					<div className="configurator_sidebar_container_items">					
					{this.state.data.map((item,i) => (
						<div key={i} onClick={this.selectOption.bind(this,i)} className={ "configurator_sidebar_container_items_item " + ( item.options.length > 1 ? "visibile" : "hidden" )}>
							<div className="configurator_sidebar_container_items_item_image">
								<img src={item.image} />
							</div>
							<p>{item.text}</p>						
						</div>
					))}
					</div>
				</div>	
			</div>
		)
	}

}